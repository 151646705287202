import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout } from '@components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { StaticImage } from 'gatsby-plugin-image';
import Contact from '../../components/sections/contact';
// const StyledMainContainer = styled.main`
//   counter-reset: section;
// `;

const StyledProjectSection = styled.section`
  margin-top: 100px;
  padding: 20px;
  max-width: 1200px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
  .section {
    margin-top: 20px;
  }
  .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0 10px;
    padding: 0;
    list-style: none;
    color: var(--lightest-slate);
    justify-content: flex-start;

    li {
      margin: 0 20px 5px 0;
      color: var(--light-slate);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      white-space: nowrap;
      color: var(--green);
      font-family: var(--font-mono);
      font-weight: 400;
    }

    @media (max-width: 768px) {
      margin: 10px 0;

      li {
        margin: 0 0 5px 20px;
        margin: 0 10px 5px 0;

        @media (max-width: 768px) {
          margin: 0 10px 5px 0;
        }
      }
    }
  }
  .screenshots {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .screenshot {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-style: italic;
  }
  .link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }

  .video {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .screenshots images {
    display: flex;
    flex-wrap: wrap;
  }
  .screenshot images StaticImage {
    width: 300;
  }
`;
// const StyledText = styled.div`
//   ul.skills-list {
//     display: grid;
//     grid-template-columns: repeat(2, minmax(140px, 200px));
//     grid-gap: 0 10px;
//     padding: 0;
//     margin: 20px 0 0 0;
//     overflow: hidden;
//     list-style: none;

//     li {
//       position: relative;
//       margin-bottom: 10px;
//       padding-left: 20px;
//       font-family: var(--font-mono);
//       font-size: var(--fz-xs);

//       &:before {
//         content: '▹';
//         position: absolute;
//         left: 0;
//         color: var(--green);
//         font-size: var(--fz-sm);
//         line-height: 12px;
//       }
//     }
//   }
// `;

const tech = ['Unity', 'C#', '.NET Core', 'PostgreSQL', 'Heroku', 'CI/CD Pipelines'];

const NeonOrbitProject = ({ location }) => {
  return (
    <Layout location={location}>
      <div className="container">
        <StyledProjectSection>
          <div className="App">
            <header className="App-header">
              <h1 className="big-heading">Neon Orbit</h1>
            </header>
            <div>
              <div className="section overview">
                <h2>Overview</h2>
                <p>
                  Neon Orbit is an exciting and dynamic game that offers players an endless
                  challenge. By combining the key elements of an endless runner with the concept of
                  rotation, players are presented with a unique and thrilling gaming experience. As
                  players navigate their way through the game, they must rotate the platforms to
                  pass through various obstacles. What makes it different from all its competitors
                  is limitations to the direction of rotation. Some can rotate exclusively in one
                  direction, either left or right, while others possess the versatility of rotating
                  in both directions. This creates a challenging and engaging gameplay experience
                  that keeps players on their toes.
                </p>
              </div>
              <div className="section technologies">
                <h2>Technologies</h2>
                {tech.length && (
                  <ul className="project-tech-list">
                    {tech.map((tech, i) => (
                      <li key={i}>{tech}</li>
                    ))}
                  </ul>
                )}
              </div>

              {/* <div className="section features">
                <h2>Key Features</h2>
                <ul>
                  <li>
                    Patient-Centric Dashboard: Access a centralized dashboard displaying a snapshot
                    of each patient's medical history, upcoming appointments, and personalized care
                    plans.
                  </li>
                  <li>
                    Visit Tracking: Easily record and monitor individual patient visits, including
                    symptoms, treatments, and prescribed medications. The intuitive interface
                    ensures quick data entry, saving valuable time during consultations.
                  </li>
                  <li>
                    Automated Reporting: Generate comprehensive reports with just a few clicks,
                    providing a detailed overview of a patient's medical journey. These reports
                    include vital statistics, treatment plans, and historical data, facilitating
                    efficient communication with patients and colleagues.
                  </li>
                  <li>
                    Conclusion and Decision Support: Utilize the platform's intelligent decision
                    support system, which analyzes patient data to suggest potential conclusions and
                    treatment options. This assists doctors in making informed decisions tailored to
                    each patient's unique needs.
                  </li>
                  <li>
                    Enhanced Communication: Facilitate seamless communication between healthcare
                    professionals within the platform. Share insights, collaborate on complex cases,
                    and ensure a coordinated approach to patient care.
                  </li>
                </ul>
              </div>
              <div className="section benefits">
                <h2>Benefits</h2>
                <ul>
                  <li>
                    Time Efficiency: By simplifying data entry and automating report generation,
                    doctors can focus more on patient interactions and less on administrative tasks,
                    leading to increased efficiency during appointments.
                  </li>
                  <li>
                    Informed Decision-Making: Access to detailed analytics and intelligent insights
                    empowers doctors to make well-informed decisions, enhancing the overall quality
                    of patient care.
                  </li>
                  <li>
                    Improved Patient Engagement: By having a holistic view of a patient's medical
                    history readily available, doctors can engage patients in more meaningful
                    conversations about their health, fostering a stronger doctor- patient
                    relationship.
                  </li>
                </ul>
              </div> */}

              <div className="section video">
                <h2 style={{ alignSelf: 'flex-start' }}>Gameplay</h2>
                <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/BnN18A_sLkw?si=QcAnFU_zVttoPR0n"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen></iframe>
                <a
                  className="link"
                  href="https://bulletproofcupid.itch.io/neon-orbit"
                  target="_blank">
                  Play Here
                </a>
              </div>
              {/* <div className="section screenshots">
                <h2>Screenshots</h2>
                <h3>
                  The following screenshots showcase the functionallities of the dermdoc application
                </h3>

                <div className='images'>
                <img
                width={200}
                height={300}
                  src="../../../content/featured/neon-orbit/neon-orbit-1.png"
                  alt="A dinosaur"
                />

                <StaticImage
                  src="../../../content/featured/neon-orbit/neon-orbit-5.png"
                  alt="A dinosaur"
                />

                <StaticImage
                  src="../../../content/featured/neon-orbit/neon-orbit-6.png"
                  alt="A dinosaur"
                />

                <StaticImage
                  src="../../../content/featured/neon-orbit/neon-orbit-7.png"
                  alt="A dinosaur"
                />
                </div>
              </div> */}
            </div>
          </div>
          <Contact />
        </StyledProjectSection>
      </div>
    </Layout>
  );
};

NeonOrbitProject.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NeonOrbitProject;
